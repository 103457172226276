

$(document).ready(function() {

    // $("body").queryLoader2({
    //     barColor: "#fff",
    //     backgroundColor: "#E85A64",
    //     percentage: true,
    //     barHeight: 2,
    //     minimumTime: 5000,
    //     fadeOutTime: 1000,
    //     completeAnimation: "grow",
    //     onProgress: function() {
    //         $("#typed").typed({
    //             stringsElement: $('#typed-strings'),
    //             typeSpeed: 50,
    //             backDelay: 1000,
    //             contentType: 'html',
    //             callback: function() {
    //                 $(".typed-cursor").addClass("inactive");
    //             }
    //         })
    //     }
    // });

    //$('#preloader').delay(10000).fadeOut(500); // will fade out the white DIV that covers the website.
    // $('#qLoverlay').append('<div id="typed-strings"><p></p></div><span id="typed"></span> ');



    $('.section--hero .img-intro, .section--hero .text-intro').animate({
        opacity: 0
    }, 0);

    $('.section--hacemos h2, .section--hacemos h6, .section--hacemos p, .section--hacemos .block-module-windows, .section--hacemos .block-info-1, .section--hacemos .block-info-2, .section--hacemos .block-info-3').animate({
        opacity: 0
    }, 0);

    $('.section--mobile h2, .section--mobile .logo-client, .section--mobile h6, .section--mobile p, .section--mobile .block-module-mobile').animate({
        opacity: 0
    }, 0);

    $('.section--desktop h2, .section--desktop .logo-client, .section--desktop h6, .section--desktop p, .section--desktop .block-module-notebook').animate({
        opacity: 0
    }, 0);

    $('.section--clientes h2, .section--clientes h6, .section--clientes p, .section--clientes .cliente-1, .section--clientes .cliente-2, .section--clientes .cliente-3, .section--clientes .cliente-4, .section--clientes .cliente-5, .section--clientes .cliente-6, .section--clientes .cliente-7, .section--clientes .cliente-8 ').animate({
        opacity: 0
    }, 0);

    $('.section--contacto h2, .section--contacto h6, .section--contacto .form-contact, .section--contacto .block-contact-phone').animate({
        opacity: 0
    }, 0);


   $('#fullpage').fullpage({
        //Design
        controlArrows: false,
        verticalCentered: true,
        anchors: ['hero', 'que-hacemos', 'mobile', 'desktop', 'clientes', 'contacto','footer'],
        fixedElements: '.main-header',
        responsiveWidth: 1100,
        resize: true,
        autoScrolling: true,
        css3: true,
        //scrollingSpeed: 500,
        navigation: true,
        navigationPosition: 'right',
        navigationTooltips: ['Hello', 'What we do', 'Mobile success case', 'Web success case', 'Clients', 'Contact',''],
        scrollBar: false,
        slidesNavigation: true,
        slidesToSections: false,
        slidesNavPosition: 'bottom',

        responsiveSlides: false,

        touchSensitivity: 10,


        // afterRender: function(){
        //     new WOW().init();
        // },
        afterLoad: function(anchorLink, index) {
            var loadedSection = $(this);
            $('.fp-slidesNav ul').addClass('container');
            //using index

            if (index == 1) {
              //show bitflow logo when go to page 1
                document.getElementById('logo-bitflow').style.display = 'block';
                /*
                $('#footer').animate({
                    marginTop: "-80px"
                }, 500);
                $('.bar1, .bar2, .bar3').css('background-color', '#fff');

                */

                $('.section--hero .img-intro').animate({
                    opacity: 1
                });

                $('.section--hero .text-intro').animate({
                    opacity: 1
                });

                window.setTimeout(function() {
                    $(".section--hero .img-intro");
                });
                window.setTimeout(function() {
                    $(".section--hero .text-intro");
                });

            }


            if (index == 2) {
              // hide bitflow logo for all pages
                document.getElementById('logo-bitflow').style.display = 'none';

                $('#overlay1').animate({
                    opacity: 0
                });

                $('.section--hacemos h6').animate({
                    opacity: 1
                });

                $('.section--hacemos h2').animate({
                    opacity: 1
                });

                $('.section--hacemos p').animate({
                    opacity: 1
                });

                $('.section--hacemos .block-module-windows').animate({
                    opacity: 1
                });

                $('.section--hacemos .block-info-1').animate({
                    opacity: 1
                });

                $('.section--hacemos .block-info-2').animate({
                    opacity: 1
                });

                $('.section--hacemos .block-info-3').animate({
                    opacity: 1
                });


                window.setTimeout(function() {
                    $(".section--hacemos h6").addClass('animated fadeInUp');
                });
                window.setTimeout(function() {
                    $(".section--hacemos h2").addClass('animated fadeInUp');
                });
                window.setTimeout(function() {
                    $(".section--hacemos p").addClass('animated fadeInUp');
                });
                window.setTimeout(function() {
                    $(".section--hacemos .block-module-windows").addClass('animated fadeInLeft');
                });

                 window.setTimeout(function() {
                    $(".section--hacemos .block-info-1").addClass('animated fadeInUp');
                });

                 window.setTimeout(function() {
                    $(".section--hacemos .block-info-2").addClass('animated fadeInUp');
                });

                 window.setTimeout(function() {
                    $(".section--hacemos .block-info-3").addClass('animated fadeInUp');
                });

            }

            if (index == 3) {
                $('.section--mobile h6').animate({
                    opacity: 1
                });

                $('.section--mobile .logo-client').animate({
                    opacity: 1
                });

                $('.section--mobile h2').animate({
                    opacity: 1
                });

                $('.section--mobile p').animate({
                    opacity: 1
                });

                $('.section--mobile .block-module-mobile').animate({
                    opacity: 1
                });


                window.setTimeout(function() {
                    $(".section--mobile h6").addClass('animated fadeInUp');
                });
                window.setTimeout(function() {
                    $(".section--mobile .logo-client").addClass('animated fadeInUp');
                });

                window.setTimeout(function() {
                    $(".section--mobile h2").addClass('animated fadeInUp');
                });
                window.setTimeout(function() {
                    $(".section--mobile p").addClass('animated fadeInUp');
                });

                window.setTimeout(function() {
                    $(".section--mobile .block-module-mobile").addClass('animated fadeInRight');
                });


                
            }

            if (index == 4) {
                $('.section--desktop h6').animate({
                    opacity: 1
                });

                $('.section--desktop .logo-client').animate({
                    opacity: 1
                });

                $('.section--desktop h2').animate({
                    opacity: 1
                });

                $('.section--desktop p').animate({
                    opacity: 1
                });

                $('.section--desktop .block-module-notebook').animate({
                    opacity: 1
                });


                window.setTimeout(function() {
                    $(".section--desktop h6").addClass('animated fadeInUp');
                });
                window.setTimeout(function() {
                    $(".section--desktop h2").addClass('animated fadeInUp');
                });
                window.setTimeout(function() {
                    $(".section--desktop p").addClass('animated fadeInUp');
                });
                window.setTimeout(function() {
                    $(".section--desktop .block-module-notebook").addClass('animated fadeInRight');
                });

              
            }


            if (index == 5) {
                $('.section--clientes h6').animate({
                    opacity: 1
                });

                $('.section--clientes h2').animate({
                    opacity: 1
                });

                $('.section--clientes p').animate({
                    opacity: 1
                });

                $('.section--clientes .cliente-1').animate({
                    opacity: 1
                });

                $('.section--clientes .cliente-2').animate({
                    opacity: 1
                });

                $('.section--clientes .cliente-3').animate({
                    opacity: 1
                });

                $('.section--clientes .cliente-4').animate({
                    opacity: 1
                });

                $('.section--clientes .cliente-5').animate({
                    opacity: 1
                });

                $('.section--clientes .cliente-6').animate({
                    opacity: 1
                });

                $('.section--clientes .cliente-7').animate({
                    opacity: 1
                });

                $('.section--clientes .cliente-8').animate({
                    opacity: 1
                });


                window.setTimeout(function() {
                    $(".section--clientes h6").addClass('animated fadeInUp');
                });
                window.setTimeout(function() {
                    $(".section--clientes h2").addClass('animated fadeInUp');
                });
                window.setTimeout(function() {
                    $(".section--clientes p").addClass('animated fadeInUp');
                });

                window.setTimeout(function() {
                    $(".section--clientes .cliente-1").addClass('animated fadeInUp');
                });

                window.setTimeout(function() {
                    $(".section--clientes .cliente-2").addClass('animated fadeInUp');
                });

                window.setTimeout(function() {
                    $(".section--clientes .cliente-3").addClass('animated fadeInUp');
                });

                window.setTimeout(function() {
                    $(".section--clientes .cliente-4").addClass('animated fadeInUp');
                });

                window.setTimeout(function() {
                    $(".section--clientes .cliente-5").addClass('animated fadeInUp');
                });

                window.setTimeout(function() {
                    $(".section--clientes .cliente-6").addClass('animated fadeInUp');
                });
                window.setTimeout(function() {
                    $(".section--clientes .cliente-7").addClass('animated fadeInUp');
                });
                window.setTimeout(function() {
                    $(".section--clientes .cliente-8").addClass('animated fadeInUp');
                });

            }

            if (index == 6) {
                $('.section--contacto h6').animate({
                    opacity: 1
                });

                $('.section--contacto h2').animate({
                    opacity: 1
                });

                $('.section--contacto .form-contact').animate({
                    opacity: 1
                });

                $('.section--contacto .block-contact-phone').animate({
                    opacity: 1
                });


                window.setTimeout(function() {
                    $(".section--contacto h6").addClass('animated fadeInUp');
                });
                window.setTimeout(function() {
                    $(".section--contacto h2").addClass('animated fadeInUp');
                });
                window.setTimeout(function() {
                    $(".section--contacto .form-contact").addClass('animated fadeInLeft');
                });

                window.setTimeout(function() {
                    $(".section--contacto .block-contact-phone").addClass('animated fadeInRight');
                });
            }



        }
    });

    var startSlideSections = function() {
        setInterval(function () {
          $.fn.fullpage.moveSlideRight();
        }, 10000);
    };

    startSlideSections();

    retinajs($('img, .bg-retina'));

    //$('header.main-header').midnight();



    $('[data-toggle="tooltip"]').tooltip();


     var $lateral_menu_trigger = $('.primary-nav-trigger'),
        $content_wrapper = $('.main-site'),
        $navigation = $('.main-header');


    $lateral_menu_trigger.on('click', function(){
        $lateral_menu_trigger.toggleClass('is-clicked');
        $('.main-header').toggleClass('menu-is-open');

        //in firefox transitions break when parent overflow is changed, so we need to wait for the end of the trasition to give the body an overflow hidden
        if( $('.menu-mobile').hasClass('is-visible') ) {
            $('.menu-mobile').removeClass('is-visible').one('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend',function(){
                $('body').removeClass('overflow-hidden');
            });
        } else {
            $('.menu-mobile').addClass('is-visible').one('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend',function(){
                $('body').addClass('overflow-hidden');
            });
        }
    });





    //close lateral menu clicking outside the menu itself
    $('.menu-mobile ul li a').on('click', function(event){
        if( !$(event.target).is('.primary-nav-trigger') ) {
            $lateral_menu_trigger.removeClass('is-clicked');
            $navigation.removeClass('menu-is-open');

            //in firefox transitions break when parent overflow is changed, so we need to wait for the end of the trasition to give the body an overflow hidden
            if( $('.menu-mobile').hasClass('is-visible') ) {
                $('.menu-mobile').removeClass('is-visible').one('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend',function(){
                    $('body').removeClass('overflow-hidden');
                });
            } else {
                $('.menu-mobile').addClass('is-visible').one('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend',function(){
                    $('body').addClass('overflow-hidden');
                });
            }
        }
    });



    // Hide Header on on scroll down
    var didScroll;
    var lastScrollTop = 0;
    var delta = 5;
    var navbarHeight = $('.main-header').outerHeight();

    $(window).scroll(function(event){
        didScroll = true;
    });

    setInterval(function() {
        if (didScroll) {
            hasScrolled();
            didScroll = false;
        }
    }, 250);

    function hasScrolled() {
        var st = $(this).scrollTop();

        // Make sure they scroll more than delta
        if(Math.abs(lastScrollTop - st) <= delta)
            return;

        // If they scrolled down and are past the navbar, add class .nav-up.
        // This is necessary so you never see what is "behind" the navbar.
        if (st > lastScrollTop && st > navbarHeight){
            // Scroll Down
            $('.main-header').removeClass('nav-down').addClass('nav-up');
        } else {
            // Scroll Up
            if(st + $(window).height() < $(document).height()) {
                $('.main-header').removeClass('nav-up').addClass('nav-down');
            }
        }

        lastScrollTop = st;
    }
    // language-js
    // $(function() {
      // use navigator property to know language browser
      // var lang = navigator.language.substring(0, 2);
      // if (lang != 'es'){
      // var lang = 'en';
      // document.getElementById("img-intro-hello").src="assets/img/text_intro_hello.png";
       $("#logo-bitflow").attr('data-original-title', 'Are you looking for new challenges? Join us').tooltip('fixTitle').tooltip('show');
      // } else {
      // document.getElementById("img-intro-hello").src="assets/img/text_intro_hola.png";
      // $("#logo-bitflow").attr('data-original-title', '¿Estas buscando nuevos desafíos? Sumate a Bitflow!').tooltip('fixTitle').tooltip('show');
      // }
      $("#logo-bitflow").tooltip('hide');
    //   function display() {
    //     // later change to classes not id
    //     $("#firstPage").text("FIRSTPAGE".t());
    //     $("#importantDetails").text("IMPORTANTDETAILS".t());
    //     $(".weAre").text("WEARE".t());
    //     $("#experiences").text("EXPERIENCES".t());
    //     $("#uxui").text("UXUI".t());
    //     $("#mobileApp").text("MOBILEAPP".t());
    //     $("#webApp").text("WEBAPP".t());
    //     $(".mobileSuccessCase").text("MOBILESUCCESSCASE".t());
    //     $("#connectedPeople").text("CONNECTEDPEOPLE".t());
    //     $("#weHelp").text("WEHELP".t());
    //     $(".webSuccessCase").text("WEBSUCCESSCASE".t());
    //     $("#agileBuy").text("AGILEBUY".t());
    //     $("#salesChannel").text("SALESCHANNEL".t());
    //     $(".clients").text("CLIENTS".t());
    //     $("#trust").text("TRUST".t());
    //     $("#shy").text("SHY".t());
    //     $("#dropUs").text("DROPUS".t());
    //     $("#callUs").text("CALLUS".t());
    //     $(".hello").text("HELLO".t());
    //     $(".successMobile").text("SUCCESSMOBILE".t());
    //     $(".successWeb").text("SUCCESSWEB".t());
    //     $(".contact").text("CONTACT".t());
    //     $(".labelName").html("FORM_NAME".t());
    //     $(".labelCompany").html("FORM_COMPANY".t());
    //     $(".labelPhone").html("FORM_PHONE".t());
    //     $(".labelEmail").html("FORM_EMAIL".t());
    //     $(".labelMsg").html("FORM_MSG".t());
    //     $(".btnSend").html("FORM_SUBMIT".t());
    //     $('#typed-strings p').text("TEXT_LOADER".t());
    //   }
    //   Languages.init(lang, "assets/lang/", display);
    // });


    $(".form-contact").on("input propertychange", ".form-group", function(e) {
        $(this).toggleClass("with-value", !!$(e.target).val());
    }).on("focus", ".form-group", function() {
        $(this).addClass("with-focus");
    }).on("blur", ".form-group", function() {
         $(this).removeClass("with-focus");
    });

    //Quote Form
    $("#getQuote").on("click", function() {
        event.preventDefault();
        var name = $("#inputName_q");
        var company = $("#inputCompany_q");
        var phone = $("#inputPhone_q");
        var email = $("#inputEmail_q");
        var message = $("#inputText_q");
        // dont send anything if the values are empty
        if (name.val() == ''){
            name.focus();
            return false;
        }
        if (company.val() == ''){
            company.focus();
            return false;
        }
        if (phone.val() == ''){
            phone.focus();
            return false;
        }
        if (email.val() == ''){
            email.focus();
            return false;
        }
        $("#getQuote").prop('disabled', true);
        $.ajax({
                url: "/envioMail.php",
                type: "POST",
                data: {
                    nombre: name.val(),
                    company: company.val(),
                    phone: phone.val(),
                    email: email.val(),
                    mensaje: message.val()
                },
                cache: false,
                success: function() {
                    // Success message
                    swal("Nice words, Thank You!", "We'll contact you shortly. Stay tuned.", "success");
                    //clear all fields
                    $('.form-contact').trigger("reset");
                    $("#getQuote").prop('disabled', false);
                },
                error: function() {
                    // Fail message
                    swal("Ups.. Houston, we have a problem!", "Please e-mail us to hello@bitflowlabs.com", "error");
                    //clear all fields
                    $('.form-contact').trigger("reset");
                    $("#getQuote").prop('disabled', false);
                },
            })
    });

    //Contact Form
    $("#submitForm").on("click", function() {
        event.preventDefault();
        var name = $("#inputName");
        var company = $("#inputCompany");
        var phone = $("#inputPhone");
        var email = $("#inputEmail");
        var message = $("#inputText");
        var captcha = $("#g-recaptcha-response").val();
        
        // dont send anything if the values are empty
        if (name.val() == ''){
            name.focus();
            return false;
        }
        if (company.val() == ''){
            company.focus();
            return false;
        }
        if (phone.val() == ''){
            phone.focus();
            return false;
        }
        if (email.val() == ''){
            email.focus();
            return false;
        }
        if (!captcha){
            swal("Ups.. Houston, we have a problem!", "Please check the captcha", "error");
            return false;
        }
        $("#submitForm").prop('disabled', true);
        const data = {
            nombre: name.val(),
            company: company.val(),
            phone: phone.val(),
            email: email.val(),
            mensaje: message.val(),
            captchaToken: captcha
        };
        $.ajax({
                url: "https://mg5ts7up7fjn56c2noo2gcdrdq0vfjtp.lambda-url.us-west-2.on.aws/",
                type: "POST",
                contentType: "application/json",
                dataType: 'json',
                data: JSON.stringify(data),
                cache: false,
                success: function(response) {
                    // Success message
                    swal("Nice words, Thank You!", "We'll contact you shortly. Stay tuned.", "success");
                    //clear all fields
                    $('.form-contact').trigger("reset");
                    $("#submitForm").prop('disabled', false);
                },
                error: function(error, status, message) {
                    // Fail message
                    swal("Ups.. Houston, we have a problem!", "Please e-mail us to hello@bitflowlabs.com", "error");
                    //clear all fields
                    $('.form-contact').trigger("reset");
                    $("#submitForm").prop('disabled', false);
                },
            })
    });

    $( "#logo-bitflow" ).on( "click", function(event) {
        $(this).attr('href', 'mailto:hello@bitflowlabs.com');
   });

});